import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ article, seoData }) => {
	const { pathname } = useLocation()
	const { site } = useStaticQuery(query)
	const { defaultTitle, defaultDescription, defaultDescriptionEn, siteUrl, defaultImage } = site.siteMetadata
	const seo = {
		title: seoData?.title || defaultTitle,
		description: seoData?.description || pathname.includes('/en/') ? defaultDescriptionEn : defaultDescription,
		image: `${siteUrl}${seoData?.opengraphImage?.localFile?.publicURL || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	}

	return (
		<Helmet title={seo.title}>
			<meta name='description' content={seo.description} />
			<meta name='image' content={seo.image} />
			{seo.url && <meta property='og:url' content={seo.url} />}
			{!!article && <meta property='og:type' content='article' />}
			{seo.title && <meta property='og:title' content={seo.title} />}
			{seo.description && <meta property='og:description' content={seo.description} />}
			{seo.image && <meta property='og:image' content={seo.image} />}

			<meta name='twitter:card' content='summary_large_image' />
			{seo.title && <meta name='twitter:title' content={seo.title} />}
			{seo.description && <meta name='twitter:description' content={seo.description} />}
			{seo.image && <meta name='twitter:image' content={seo.image} />}

			<script>
				{`(function (f, u, s, e_, d, e, c, k, n_) {
					try{
							var cos = document.cookie.split(';');
							for (var i = 0; i < cos.length; i++) {var co = cos[i].trim().split('=');if (co[0] == 'fdTrackingType'+e) {c = co[1];}if (co[0] == 'fdEnvId') {k = co[1];}}
							f[d] = f[d] || [];window.addEventListener("load",function(){fdWinLoaded=!0});
							var p = u.getElementsByTagName(s)[0]; var sc = u.createElement(s); sc.async = true;
							sc.src = e_ + '?p=' + e + '&l=' + (d != null ? d : '') + (c != null ? '&c=' + c : '') + (k != null ? '&e=' + k : '') + '&h=' + encodeURIComponent(location.href) + (n_ != null ? '&a=' + encodeURIComponent(n_) : '');
							p.parentNode.insertBefore(sc, p);
					}catch(e){}
			})(window, document, 'script', 'https://fuse.emilfrey.ch/d/tm.js', null, 'JoVwZHSUd1', null, null, null)`}
			</script>
		</Helmet>
	)
}
export default SEO

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
}
SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
}

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				siteUrl
				defaultDescription: description
				defaultDescriptionEn: descriptionEn
				defaultImage: image
			}
		}
	}
`
